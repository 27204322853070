.app {
  display: flex;
  flex-direction: column;
  align-items: center;

  &.wide {
    display: block;
    padding: 40px;
  }

  .canvas-container {
    position: relative;

    //img {
    //  position: absolute;
    //  top: 50px;
    //  left: 50px;
    //}
  }

  footer {
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    font-size: 0.7em;
    color: #eee;
    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 5, 16, 0.4));

    a {
      &:link,
      &:visited {
        color: #eee;
      }

      &:hover {
        color: #ccc;
      }
    }
  }

  main {
    .picker-style-selectors {
      margin-bottom: 20px;

      > a {
        &:link,
        &:visited {
          color: rgb(0, 0, 238);
        }
      }

      > a,
      > span {
        padding: 0 10px;
        border-right: 1px solid #aaa;

        &:last-child {
          border-right: 0;
        }
      }
    }

    .picker-container {
      padding-left: 10px;
    }

    .fit-size-selectors {
      display: flex;
      margin-bottom: 30px;

      a,
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #eee;
        border: 1px solid #ccc;
        border-radius: 2px;
        margin-right: 10px;
        width: 32px;
        height: 32px;
        cursor: pointer;

        img {
          cursor: pointer;
          pointer-events: none;
          width: 16px;
          height: 16px;
        }

        &.disabled {
          opacity: 0.4;
          pointer-events: none;
        }
      }

      span {
        outline: solid 2px #ccc;
      }
    }

    table.canvas-container {
      $edge-border: 1px solid #aaa;

      .width-container {
        position: relative;
        display: flex;
        justify-content: center;
        border-left: $edge-border;
        border-right: $edge-border;

        .ruler {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          height: 50%;
          border-bottom: $edge-border;
          z-index: -1;
        }

        > input {
          display: block;
        }
      }

      .height-container {
        position: relative;
        border-top: $edge-border;
        border-bottom: $edge-border;

        .ruler {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          width: 50%;
          border-right: $edge-border;
          z-index: -1;
        }
      }
    }

    section {
      margin-bottom: 60px;
    }
  }
}

// Bug fix

.photoshop-picker {
  user-select: none;

  > .flexbox-fix > div:last-child > div.flexbox-fix > div:last-child {
    > div:nth-child(1),
    > div:nth-child(2) {
      //display: none;
      visibility: hidden;
    }

    > div > div > span {
      top: 0;
    }
  }
}
